import { Empty, Spin } from "antd";
import React, { Component } from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { primaryColor } from "../../colors";

type Props = {
  loading: boolean;
  data: { [category: string]: { x: number; y: number }[] };
};
type State = {};
const colors: { [gameName: string]: string } = {
  ZombieVR: primaryColor.toString(),
  TronVR: primaryColor.clone().spin(45).toString(),
  ArcheryVR: primaryColor.clone().spin(-45).toString(),
  PVPVR: primaryColor.clone().spin(160).toString(),
  Robbers: primaryColor.clone().spin(100).toString(),
  EspionageExpress: primaryColor.clone().spin(-90).toString(),
  CookdUp: primaryColor.clone().spin(90).toString(),
  ScifiEscapeVR: primaryColor.clone().spin(180).toString(),
};

export default class LineChart extends Component<Props, State> {
  renderBars = () => {
    const res = [];
    for (const [category, gameData] of Object.entries(this.props.data)) {
      res.push(
        <VictoryBar
          labelComponent={
            <VictoryTooltip
              cornerRadius={1}
              flyoutStyle={{
                stroke: "black",
                strokeWidth: 0.1,
                fill: "rgb(37 41 49)",
              }}
              style={{ fontSize: 4, fill: "#b1b1b1" }}
            />
          }
          labels={({ datum }) =>
            `${formatDate(datum.x)} ${getGameName(category)}: ${datum.y} game${
              datum.y > 1 ? "s" : ""
            } played`
          }
          barWidth={5}
          alignment="start"
          barRatio={0.95}
          key={category}
          style={{
            data: { fill: colors[category] || "black" },
            parent: { border: "1px solid #ccc" },
          }}
          data={gameData}
          domain={this.calculateDomain()}
        />
      );
    }

    return res;
  };
  getMonthCount = () => {
    let monthCount = 0;
    for (const data of Object.values(this.props.data)) {
      if (Object.values(data).length > monthCount)
        monthCount = Object.values(data).length;
    }
    return Math.min(5, monthCount);
  };
  getMonthTickValues = () => {
    const tickValues: number[] = [];
    for (const data of Object.values(this.props.data)) {
      data.forEach((date) => {
        if (tickValues.indexOf(date.x) === -1) tickValues.push(date.x);
      });
    }
    return tickValues;
  };
  calculateDomain = () => {
    let startDate = new Date(3000, 0).getTime();
    let endDate = new Date(2000, 0).getTime();
    for (const data of Object.values(this.props.data)) {
      Object.values(data).forEach((date) => {
        const month = date.x;
        if (month < startDate) startDate = month;
        if (month > endDate) endDate = month;
      });
    }

    let dateDelta = endDate - startDate;
    const minimumDomain = monthsToMs(10);
    if (dateDelta < minimumDomain) {
      startDate = startDate - Math.max(minimumDomain, dateDelta) / 2;
      endDate = endDate + Math.max(minimumDomain, dateDelta) / 2;
    }

    return { x: [startDate, endDate] as [number, number] };
  };
  render() {
    const axisStyle = {
      axis: { stroke: "#ffffff0f" },
      grid: { stroke: "#ffffff0f", strokeDasharray: "none" },
      ticks: { stroke: "#ffffff0f", display: "none" },

      tickLabels: { fontSize: 4, padding: 3, fill: "#5a717c" },
    };
    if (this.props.loading) return <Spin />;
    if (!this.props.data || !Object.keys(this.props.data).length)
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    return (
      <VictoryChart
        domain={this.calculateDomain()}
        domainPadding={{ x: [1, 1] }}
        height={100}
        padding={14}
        theme={VictoryTheme.material}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryLegend
          itemsPerRow={4}
          x={20}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{
            border: { stroke: "black" },
            labels: { fontSize: 5 },
          }}
          data={getLegend()}
        />
        <VictoryStack>{this.renderBars()}</VictoryStack>

        <VictoryAxis style={axisStyle} dependentAxis />
        <VictoryAxis
          style={axisStyle}
          tickFormat={formatDate}
          fixLabelOverlap
          tickValues={this.getMonthTickValues()}
        />
      </VictoryChart>
    );
  }
}

function getLegend() {
  const legend = [];

  for (const [gameName, color] of Object.entries(colors)) {
    legend.push({ name: getGameName(gameName), symbol: { fill: color } });
  }
  return legend;
}
function formatDate(timestamp: number) {
  const date = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    months[date.getMonth()] + " '" + date.getFullYear().toString().slice(-2)
  );
}

function monthsToMs(month: number) {
  return month * 2628e6;
}

export function getGameName(key: string) {
  const gameNames: { [key: string]: string } = {
    ZombieVR: "Dead Ahead",
    TronVR: "Cyber Shock",
    ArcheryVR: "Arrowsong",
    PVPVR: "Quantum Arena",
    Robbers: "Cops Vs Robbers",
    EspionageExpress: "Espionage Express",
    CookdUp: "Cook'd Up",
    ScifiEscapeVR: "Wayfinders",
    WizardApprenticeVR: "Wizard Academy",
  };

  return gameNames[key] || key;
}
