import React from "react";
import { Image } from "antd";
type ImageWithDescriptionProps = {
  src: string;
  width: number | string;
  description: string;
  style?: React.CSSProperties;
};
const ImageWithDescription = ({
  width,
  src,
  description,
  style,
}: ImageWithDescriptionProps) => {
  return (
    <div
      style={{
        ...style,
        margin: "2em 0",
        textAlign: "justify",
        width,
        color: "#777",
      }}
    >
      <Image
        preview={true}
        width={width}
        style={{ display: "block", margin: "0 auto" }}
        src={src}
        alt={description}
      />
      <p>{description}</p>
    </div>
  );
};

export default ImageWithDescription;
