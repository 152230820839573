import { Button, Card, Table, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import Api from "../../api";
import { getGameName } from "../../Components/charts/StackedBarChart";
import { showError } from "../../utils";
import { Session } from "../Dashboard";
import { ExportOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
const { Title } = Typography;

export const SessionOverview = () => {
  const [sessions, setSessions] = useState<Session[]>();
  useEffect(() => {
    const fetchSessions = async (month?: {}) => {
      try {
        const newSessions: Session[] = await Api.get(
          "/api/stats/games_played",
          { month }
        );
        newSessions.sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
        setSessions(newSessions);
      } catch (error) {
        showError("Failed to get sessions", error);
      }
    };
    fetchSessions();
  }, []);

  const exportCsv = () => {
    const rows: string[] = ["Date,Player Count, Game, Credits"];
    sessions?.forEach((session) => {
      rows.push(
        renderDate(session.timestamp) +
          "," +
          (session.player_count || 0).toString() +
          "," +
          getGameName(session.game) +
          "," +
          session.credits
      );
    });
    downloadCSV(rows.join("\n"));
  };
  return (
    <Card>
      <Title level={3}>Session Overview</Title>
      <br />
      <Button onClick={exportCsv} icon={<ExportOutlined />}>
        Export CSV
      </Button>
      <br />
      <br />

      <Table
        style={{ maxHeight: 500, overflow: "auto" }}
        pagination={{ pageSize: 50 }}
        size="small"
        dataSource={sessions}
        columns={columns}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Date",
    dataIndex: "timestamp",
    key: "date",
    render: (x: string) => renderDate(x),
  },
  {
    title: "game",
    dataIndex: "game",
    key: "game",
    render: (x: string) => getGameName(x),
  },
  {
    title: "player count",
    dataIndex: "player_count",
    key: "player_count",
  },
  {
    title: "credits",
    dataIndex: "credits",
    key: "credits",
  },
];

function renderDate(x: string) {
  const date = new Date(x);

  return (
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
}

async function downloadCSV(contents: string) {
  try {
    const file = new Blob([contents], { type: "text/csv" });
    FileSaver.saveAs(file, "sessionOverview.csv");
  } catch (error) {
    showError("Failed to export csv", error);
  }
}
