/* eslint-disable react/no-unescaped-entities */
import { Divider, Typography } from "antd";
import React, { Component } from "react";
import ImageWithDescription from "../../Components/ImageWithDescription";
import Manual, { ManualData } from "../../Components/Manual";
import { quickStartData } from "./QuickStart";

const { Title } = Typography;
export default class FullManual extends Component {
  render() {
    return (
      <div>
        <Title>Hero Zone Manual</Title>
        <Divider />
        <Manual data={data} />
      </div>
    );
  }
}

const data: ManualData[] = [
  {
    title: "Requirements",
    sections: [
      {
        subtitle: "Play Area",
        content: (
          <span>
            Hero Zone requires a play area of 4.5m by 4.5m (14.8ft). It is also
            highly recommended that you have at least 50 cm of free space around
            the play area to prevent players from hitting objects or walls
            around the area which may result in injuries or damages to the
            system or the surrounding area.
          </span>
        ),
      },

      {
        subtitle: "Tracking Elements",
        content: (
          <span>
            The headsets use cameras for their tracking. To make the tracking
            more reliable, we recommend adding tracking elements to the room
            your play space is located in. These are simple shapes you place on
            the floor and/or walls near the play area. Tape and stickers work
            well to improve tracking. Keep the following in mind: <br />
            <br />
            <ul>
              <li>
                Use colours that have a lot of contrast with the surface you
                place them on.
                <br /> I.e. white tape on a dark wall or black tape on a white
                wall.
              </li>
              <li>Tracking elements should be asymmetric</li>
              <li>Sharp corners and simple shapes are ideal.</li>
            </ul>
            <ImageWithDescription
              src="images/manual/trackingElements.png"
              width="90%"
              description="Example tracking elements"
            />
          </span>
        ),
      },

      {
        subtitle: "Lighting",
        content: (
          <span>
            The lighting around your play space is important for reliable
            tracking. Ideally, the play area is evenly lit without any overly
            bright or contrasting light sources. Windows and reflective surfaces
            can cause tracking issues. The Quest controllers use infrared
            cameras for their tracking. Sunlight or very bright lights may cause
            issues.
          </span>
        ),
      },
    ],
  },
  ...quickStartData,
  {
    title: "Games",
    sections: [
      {
        subtitle: "Waiting Room",
        content: (
          <span>
            When players first put on their headset, they’ll be greeted by an
            interactive waiting room where they can wait until the other players
            are set up. As they wait, they can interact with the items placed
            around the room such as arcade cabinets, claw machines and food.
            When a game ends, players will automatically return to the waiting
            room and be shown the High Scores of the game they just played. They
            can use the 3D keyboard to type in their team name which will be
            added to the scoreboard.
          </span>
        ),
      },

      {
        subtitle: "Dead Ahead",
        content: (
          <span>
            <img
              style={{ float: "left", height: 400, margin: "0 2em 2em 0" }}
              src="images/manual/DeadAhead.jpg"
              alt="Dead Ahead"
            />
            <ul>
              <li>
                <b>Genre:</b> Shooter
              </li>
              <li>
                <b>Difficulty:</b> Medium to Hard
              </li>
              <li>
                <b>Comfort Rating:</b> Comfortable
              </li>
              <li>
                <b>Average Playtime:</b> 10 Minutes
              </li>
            </ul>
            Dead Ahead is a cooperative shooter where players fight against
            hordes of zombies in order to survive until the game comes to an
            end. Players can use several weapons found around the play area and
            repair barricades as the zombies attempt to break through.
            <ImageWithDescription
              src="images/manual/gripButton.png"
              description="Use the grip button to pick up weapons. You need to hold down the grip button or you'll drop the weapon."
              width={300}
            />
            <ImageWithDescription
              src="images/manual/triggerButton.png"
              description="Use the trigger button to fire."
              width={300}
            />
          </span>
        ),
      },

      {
        subtitle: "Arrowsong",
        content: (
          <span>
            <img
              style={{ float: "left", height: 400, margin: "0 2em 2em 0" }}
              src="images/manual/Arrowsong.jpg"
              alt="Arrowsong"
            />
            <ul>
              <li>
                <b>Genre:</b> Archery/ RPG
              </li>
              <li>
                <b>Difficulty:</b> Medium to Hard
              </li>
              <li>
                <b>Comfort Rating:</b> Comfortable
              </li>
              <li>
                <b>Average Playtime:</b> 10-13 Minutes
              </li>
            </ul>
            Arrowsong is an archery game where players defend a single objective
            from evil goblins using bows and arrows as well as abilities they
            can unlock throughout the game. The goal is to prevent the goblins
            from destroying their objective by working together and using their
            abilities to slow the goblins’ progress.
            <ImageWithDescription
              src="images/manual/triggerButton.png"
              description="Use the trigger button to shoot arrows."
              width={300}
            />
          </span>
        ),
      },
      {
        subtitle: "Cyber Shock",
        content: (
          <span>
            <img
              style={{ float: "left", height: 400, margin: "0 2em 2em 0" }}
              src="images/manual/cyberShock.jpg"
              alt="Cyber Shock"
            />
            <ul>
              <li>
                <b>Genre:</b>Shooter
              </li>
              <li>
                <b>Difficulty:</b> : Easy to Medium
              </li>
              <li>
                <b>Comfort Rating:</b> Intense
              </li>
              <li>
                <b>Average Playtime:</b> 8 Minutes
              </li>
            </ul>
            Cybershock is a more competitive shooter where players ride a moving
            platform through a virtual world in order to destroy a computer
            virus. The goal is to reach the highest score out of all the other
            players by destroying enemies whilst still working together to
            destroy the final boss. Players can find upgrades throughout the
            levels that give them a temporary advantage over other players.
            <ImageWithDescription
              src="images/manual/triggerButton.png"
              description="Use the trigger button to shoot."
              width={300}
            />
          </span>
        ),
      },
    ],
  },
  {
    title: "Troubleshooting",
    sections: [
      {
        subtitle: "Tracking Lost",
        content: (
          <span>
            This means that the headset is having difficulties tracking its
            environment.
            <ul>
              <li>
                Cover the 4 cameras in the front of the Quest with your hands
                for a few seconds.
              </li>
              <li>
                When you uncover the cameras, try to look at an area with good
                tracking elements.
              </li>
            </ul>
            If that doesn't help you'll have to restart the headset: Hold the
            power button on the headset for 3 seconds until a menu appears.
            Select Restart to reboot your Quest.
            <br /> If the issue persists, try adding extra elements around your
            play area to improve tracking.
          </span>
        ),
      },
      {
        subtitle: "Guardian not Found",
        content: (
          <p>
            This usually happens when tracking conditions are poor. Try covering
            the cameras on the front of the Quest with your hands for a few
            seconds, then look at the floor or a wall with added tracking
            elements. Repeat the process a few times if necessary. Walking
            around in your play space can also help.
            <br />
            If that doesn't work you'll have to create a new guardian. Don't
            forget to calibrate the headset if you do so. Please refer to the{" "}
            <a href="#Requirements">Requirements</a> if this happens often.
          </p>
        ),
      },

      {
        subtitle: "Players not Calibrated Correctly",
        content: (
          <p>
            Try recalibrating the headsets.Then put on a headset and check if
            you're positioned correctly relative to your play space and other
            players.
          </p>
        ),
      },
      {
        subtitle: "Headset Stuck on Loading",
        content: (
          <span>
            <ul>
              <li>
                The game isn't running on your PC. Make sure the game is
                running. If you have issues try downloading the games again.
                This will skip any files that were correctly downloaded and
                redownload files that got corrupted.
              </li>
              <li>
                The games on your PC are being blocked by your firewall (usually
                Windows Firewall). Try temporarily disabling the firewall. If
                that fixes the problem, add exceptions to your firewall for our
                games.
              </li>

              <li>
                The games on the headset might be a different version than the
                games on the PC. Try installing the games again.
              </li>
            </ul>
          </span>
        ),
      },

      {
        subtitle:
          "Error: The following components are required to run this program: Directx Runtime",
        content: (
          <span>
            Navigate to "location of downloaded
            games/HeroZone/Games/WindowsNoEditor/Engine/Extras/Redist/en-us/"
            and run UE4PrereqSetup_x64.exe.
          </span>
        ),
      },
      {
        subtitle: "Error When Launching Game on PC",
        content: (
          <span>
            If you get an error when launching one of the games or the waiting
            room; I.e. "Couldn't start: GAME_INSTALL_LOCATION... CreateProcess()
            returned c1." This is usually caused by your anti-virus or Windows
            Defender. Try excluding the Hero Zone games folder. Then download
            the games again. This won't re-download all the files, just corrupt
            or missing files.
          </span>
        ),
      },
      {
        subtitle: "“USB Device not Recognised” When Plugging in Headset",
        content: <p>The headset is probably powered off. Turn it on.</p>,
      },

      {
        subtitle: "Controllers Are Stuck or Invisible In-Game",
        content: (
          <span>
            <ul>
              <li>
                Check if the controllers’ batteries are depleted and change them
                if needed.
              </li>
              <li>
                Check if the lights on the controllers are on. if not, turn on
                the controllers by pressing any button.
              </li>
              <li>
                Check if the controllers are paired to the headset in question.
                We recommend color coding the controllers and headsets to
                prevent them from getting mixed up.
              </li>
            </ul>
          </span>
        ),
      },

      {
        subtitle: "Game Is Paused and a Menu Is Visible",
        content: (
          <p>
            A player accidentally pressed the Oculus button. Click on the
            “Resume” button inside the Quest to continue the game.
          </p>
        ),
      },
      {
        subtitle: "Screen Is Black",
        content: (
          <span>
            <ul>
              <li>
                Is the headset turned off? Check if the light on the front of
                the headset is on. Players can sometimes accidentally push the
                power button, which turns off the screen.
              </li>
              <li>
                Something went wrong with the installation of your game(s). Try
                installing the games again through the launcher.
              </li>
            </ul>
          </span>
        ),
      },
      {
        subtitle: "Game Crashes",
        content: (
          <p>
            In the event a game crashes, you can reconnect to the game by
            navigating to the Library tab in the Oculus menu, selecting “Unknown
            Sources” in the top-right tab and selecting the “WaitingRoom”
            software from the list. The player should automatically reconnect to
            the game.
          </p>
        ),
      },
      {
        subtitle: "Game Frozen",
        content: (
          <p>
            If a headset freezes, hold down the power button for 30 seconds
            until it shuts down. Then start it back up and launch the Waiting
            Room.
          </p>
        ),
      },

      {
        subtitle: "Headset and Controllers not Moving",
        content: (
          <p>
            If you chose the “Continue” option whenever the headset displayed a
            “Tracking Lost” message, your headset will be put in the 3DOF mode
            which disables tracking of the headset and controllers. To solve
            this issue, locate the “Tracking” button in the options menu. Press
            the button to re-enable tracking and solve the issue
          </p>
        ),
      },
      // Don't think this happens anymore?
      // {
      //   subtitle: "Installer Does Nothing",
      //   content: (
      //     <ol>
      //       <li>Shift+right click on the installer. Click on copy as path.</li>

      //       <li>Press the Windows key and type in "cmd".</li>
      //       <li>Press Enter to open up a command prompt.</li>
      //       <li>
      //         Type start and then paste the path you copied in step 1.
      //         <br /> The command should look like "start C:\Users\User\Downloads\hero-launcher Setup
      //         2.3.3.exe"
      //       </li>
      //       <li>Press enter to run the command.</li>
      //       <li>If this doesn't solve the issue please contact support.</li>
      //     </ol>
      //   ),
      // },
    ],
  },
  {
    title: "Headset Maintenance",
    sections: [
      {
        subtitle: "Headset Status",
        content: (
          <span>
            In the game view of the launcher you can see a list of all connected
            headsets. The icons indicate the battery status of the headset and
            controllers. You can hover over the icons to see the exact battery
            percentages.
            <br />
            <br />
            The Quest has an average battery life of around 2 hours if actively
            used and takes around 2 hours to fully charge. Combined with
            external battery packs you can extend the battery life to up to 7
            hours of active use. Battery packs can be swapped out at any time,
            further extending the potential up time of the headset. Make sure to
            have at least 4 headsets charged at all times to prevent potential
            issues with larger groups of players.
            <ImageWithDescription
              src="images/manual/batteryIcons.jpg"
              description="Hover over the icons to check the battery level"
              width={300}
            />
          </span>
        ),
      },
      {
        subtitle: "Charging the headset",
        content: (
          <span>
            To charge the headset, locate the USB-C port on the left side of the
            headset. Connect the headset using a USB-C to USB-A cable with a USB
            charger. The status of the headset can be seen by the colouration of
            the light above the power button. Red means the battery is low.
            Orange means the battery is charging. Green means the battery is
            fully charged and the Quest is ready to be used.
          </span>
        ),
      },

      {
        subtitle: "Charging the controllers",
        content: (
          <span>
            Each controller uses a single AA battery. First, locate and press
            down on the small arrow on the side of each controller. Keep a good
            grip on the detachable section of the controller and slide it
            downwards to open up the controller. Remove the battery and replace
            it with a new one, then close up the controller by sliding the
            removable part back up.
          </span>
        ),
      },

      {
        subtitle: "Cleaning the headsets",
        content: (
          <span>
            It's extremely important to keep the headset and controllers clean
            and free of bacteria. <br /> <br />
            <ul>
              <li>
                Use disinfecting wipes to clean the facemask, head straps and
                controllers after each use.
              </li>
              <li>
                Clean the lenses daily (Or whenever stains are visible) using a
                dry microfiber cloth. Never use wet cloth, alcohol or abrasive
                cleaning products to clean the lenses as these may damage the
                lenses.
              </li>
            </ul>
          </span>
        ),
      },
    ],
  },
  {
    title: "Pairing bHaptics Vest",
    content: (
      <div>
        <ol>
          <li>
            Press the triangle-shaped button on the back of your haptic vest to
            turn it on.
            <img
              className="manualImage"
              src="/images/manual/bhapticsButton.png"
              alt="bHaptics vest power button"
            />
          </li>

          <li>
            Put on the headset and navigate to the Devices menu located in the
            settings.
            <img
              className="manualImage"
              src="/images/manual/devices.png"
              alt="Quest devices menu"
            />
          </li>
          <li>
            Select Bluetooth Pairing
            <img
              className="manualImage"
              src="/images/manual/bluetooth.png"
              alt="Bluetooth Pairing"
            />
          </li>
          <li>
            From the list of local devices, select the bHaptics vest and press
            Pair. Once the pairing is completed, you’re ready to use the vest
            with all Hero Zone games.
          </li>
          <li>
            Repeat the steps above for each headset-vest pair you want to use.
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: "Health and Safety",
    sections: [
      {
        subtitle: "Safety Instructions",
        content: (
          <span>
            To prevent damage and injuries, keep the following safety
            instructions in mind:
            <ul>
              <li>The guardian matches the edges of the play area.</li>
              <li>The floor in the play area is even.</li>
              <li>There are no obstacles in the play space</li>

              <li>
                No one except for the players should enter the play area during
                use.
              </li>
              <li>The headsets are worn correctly and comfortably</li>
            </ul>
          </span>
        ),
      },
      {
        subtitle: "Comfort",
        content: (
          <p>
            The use of the headset and software might be uncomfortable for
            people who have little to no experience with VR games or often
            suffer from motion sickness. Because of this, it is advised to allow
            people to grow accustomed to the VR experience or choosing a game
            with a Comfortable rating. If a player starts feeling dizzy,
            nauseous or unwell, it is advised for them to take off the headset
            and leave the game. It is recommended to only use the headsets when
            not suffering from: • Sleepiness • Exhaustion • Alcohol / Drugs •
            Digestive Issues • Stress / Anxiety • Headaches / Migraines / Ear
            Pains
          </p>
        ),
      },
      {
        subtitle: "Health Warnings",
        content: (
          <span>
            Using a VR headset and playing VR games might be an uncomfortable
            experience for people prone to certain elements found within or
            those who suffer from prior health conditions. For a full list of
            possible complications and health warnings, read the official
            documentation on{" "}
            <a
              href="https://www.oculus.com/legal/health-and-safety-warnings/"
              target="_blank"
              rel="noopener noreferrer"
            >
              the Oculus website
            </a>
            <br />
            It is discouraged to use a VR headset if the person in question
            suffers from medical conditions such as:
            <ul>
              <li>Heart Conditions</li>
              <li>Epilepsy</li>
              <li>Seizures</li>
              <li>Balance Issues</li>
              <li>Severe Eye Conditions</li>
              <li>Contagious Skin Conditions</li>
              <li>Using a Pacemaker, Defibrillator or Hearing Aid</li>
              <li>Pregnancy</li>
              <li>Other severe medical conditions</li>
            </ul>
          </span>
        ),
      },
    ],
  },
];
