import React from "react";
import { Typography } from "antd";
import ImageWithDescription from "../../Components/ImageWithDescription";
const { Title } = Typography;

export const DiagnoseManual = () => {
  return (
    <div style={{ paddingBottom: 800, maxWidth: 800 }}>
      <Title level={1}>Troubleshooting with diagnostics tool</Title>
      <section>
        <p>
          The diagnostics tool is a tool that helps you to diagnose and fix
          common issues with your Hero Zone arena.
          <ImageWithDescription
            width={500}
            src="images\manual\diagnose.jpg"
            description="Diagnose tool example"
          />
        </p>
        <Title level={2}>How to use the diagnostics tool</Title>
        <p>
          To use the diagnostics tool, open the launcher settings and click on
          the Diagnose tab. The tool will automatically check for common
          problems.
        </p>
        <p>
          Connect a headset to the PC with a USB cable and wait for all the
          items in the list to update.
        </p>
      </section>
      <section>
        <Title level={3}>Matching subnet</Title>
        <p>
          The PC and headset should be connected to the same network. The first
          3 numbers of the IP address should match.This usually means they are
          on the same subnet. If they don't, the headset likely won't be able to
          connect to the PC.
        </p>
        <ImageWithDescription
          width={500}
          src="images\manual\DiagnoseTool\subnet.jpg"
          description="Matching subnet"
        />
      </section>

      <section>
        <Title level={3}>Game versions</Title>
        <p>
          The game versions on the PC and the headset must match. If they don't,
          the headset won't be able to connect to the PC, or there will be other
          issues.
        </p>
        <ImageWithDescription
          width={500}
          src="images\manual\DiagnoseTool\gameVersions.jpg"
          description="Game versions"
        />
      </section>

      <section>
        <Title level={3}>Ping test</Title>
        <p>
          The ping test checks whether the headsets can reach the server. If the
          ping test fails, that likely means there is an issue with your router
          or a firewall on the PC.
        </p>
        <ImageWithDescription
          width={500}
          src="images\manual\DiagnoseTool\pingTest.jpg"
          description="Ping test"
        />
      </section>
      <section>
        <Title level={3}>Firewall</Title>
        <p>
          If a firewall blocks the connection, headsets won't be able to connect
          to the PC. The easiest solution is to disable Windows Firewall.
          Running the launcher as an administrator will automatically add
          firewall exceptions for the games.
        </p>
        <ImageWithDescription
          width={500}
          src="images\manual\DiagnoseTool\firewall.jpg"
          description="Firewall"
        />
      </section>

      <section>
        <Title level={3}>Network adapters</Title>
        <p>
          If there is more than 1 network adapter, make sure the correct adapter
          is selected in the launcher's advanced settings. I.e. if you're using
          an ethernet connection, make sure the ethernet adapter is selected.
        </p>
        <ImageWithDescription
          width={500}
          src="images\manual\DiagnoseTool\networkAdapters.jpg"
          description="Network adapters"
        />
      </section>
      <section>
        <Title level={3}>Fixed IP</Title>
        <p>
          This only applies if you want to run multiple arenas on the same
          network with the fixed IP setting. If the setting is enabled, headsets
          will only connect with the given ip address.
        </p>
        <ImageWithDescription
          width={500}
          src="images\manual\DiagnoseTool\fixedIP.jpg"
          description="Fixed IP setting"
        />
      </section>
    </div>
  );
};
