/* eslint-disable react/no-unescaped-entities */
import { Divider, Typography } from "antd";
import React, { Component } from "react";
import ImageWithDescription from "../../Components/ImageWithDescription";
import Manual, { ManualData } from "../../Components/Manual";

const { Title } = Typography;
export default class QuickStart extends Component {
  render() {
    return (
      <div>
        <Title>Quick Start Guide</Title>

        <Divider />
        <Manual data={quickStartData} />
      </div>
    );
  }
}

export const quickStartData: ManualData[] = [
  {
    title: "Before You Start",
    content: (
      <span>
        Before starting you should have the following:
        <ul>
          <li>1 or more headsets </li>
          <li>Computer capable of running the games</li>
          <li>
            Router (You can use an existing router but ideally a separate router
            is used to run Hero Zone)
          </li>
        </ul>
        <a href="/recommended-gear" rel="noreferrer" target="_blank">
          Recommended gear
        </a>
      </span>
    ),
  },
  {
    headsetTypes: ["Quest"],
    title: "Create Developer Account",
    content: (
      <span>
        You need a developer account to install our games on your headsets.
        <br />
        <br />
        <ol>
          <li>
            Sign up for a developer account on{" "}
            <a
              href="https://developer.oculus.com/sign-up/"
              target="_blank"
              rel="noopener noreferrer"
            >
              this page.
            </a>
            <br />
          </li>
          <li>
            Create an organization{" "}
            <a
              href="https://developer.oculus.com/manage/organizations/create"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
            <br />
            Use the name of your business as the name of your organization.
          </li>
        </ol>
      </span>
    ),
  },
  {
    headsetTypes: ["Quest"],
    title: "Activate Developer Mode",
    content: (
      <span>
        Before you can install the games, you need to enable developer mode on
        each headset.
        <br />
        <br />
        <ol>
          <li>
            Open the Meta Quest app, which you used to set up the headsets.
          </li>
          <li> Open the settings menu and tap one of your headsets.</li>
          <li>
            Tap “More Settings” which will appear below the selected headset.
          </li>
          <li>Tap Developer Mode</li>
          <li>Switch on Developer Mode</li>
          <li>Repeat this for each headset you wish to use.</li>
        </ol>
        <small>
          Note: sometimes the developer mode turns itself off, if you have
          issues installing the games please check if developer mode is still
          enabled.
        </small>
      </span>
    ),
  },
  {
    headsetTypes: ["Quest"],
    title: "Oculus ADB Driver",
    content: (
      <span>
        Install the Oculus ADB Drivers so you can connect the headsets to your
        pc.
        <br />
        <br />
        <ol>
          <li>
            Download the{" "}
            <a
              href="https://developer.oculus.com/downloads/package/oculus-adb-drivers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Oculus ADB drivers
            </a>
          </li>
          <li>Download the .zip file and extract it somewhere.</li>
          <li>
            Right-click on the android_winusb.inf file and select “Install”.
          </li>
        </ol>
      </span>
    ),
  },
  {
    headsetTypes: ["Pico4"],
    title: "Activate Developer Mode",
    content: (
      <span>
        Before you can install the games, you need to enable developer mode on
        each headset.
        <br />
        <br />
        <ol>
          <li>
            In the headset's Settings, click on the “General” tab and click on
            “Software version” 8 times.
            <ImageWithDescription
              src="/images/manual/PicoDeveloperMode.png"
              width={693}
              description="How to activate developer mode on the Pico 4"
            />
          </li>

          <li>Now Developer mode should be enabled.</li>
          <li>
            Open the "Developer" tab and enable "USB Debugging"
            <ImageWithDescription
              src="/images/manual/PicoDeveloperSettings.png"
              width={693}
              description="Pico 4 Developer Settings"
            />
          </li>
        </ol>
      </span>
    ),
  },
  {
    headsetTypes: ["Focus3"],
    title: "Focus 3 Setup (without LBE)",
    content: (
      <span>
        The Focus 3 doesn't require any extra steps to be taken before you can
        install the games. Simply go through the headset's initial setup and
        connect it to your pc.
      </span>
    ),
  },
  // {
  //   headsetTypes: ["Focus3LBE"],
  //   title: "Focus 3 LBE Setup",
  //   content: (
  //     <span>
  //       If you want to use the LBE mode please follow the instructions on{" "}
  //       <Link to="/focus3-setup">this page</Link> before continuing.
  //     </span>
  //   ),
  // },
  {
    title: "Launcher Setup",
    content: (
      <span>
        <ol>
          <li>
            Download and install the{" "}
            <a
              href="https://app.herozonevr.com/latest"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hero Launcher
            </a>{" "}
          </li>
          <li>After the installation, the launcher will open.</li>
          <li>Log in with your email address and the password we provided.</li>

          <li>Click on the settings icon in the top right corner.</li>
          <li>Click on Download Games.</li>

          <li>
            Choose a folder to download our games into and click on start
            download.
          </li>
          <li>
            Once the games have been downloaded successfully you can close the
            popup.
          </li>
          <li>
            Click on Install Games. Follow the instructions there to install the
            games on each headset.
          </li>
        </ol>
        <small>
          Note: You need to connect the headset to your PC with a USB cable to
          install the games.
        </small>
        <br />

        <br />
        <video
          style={{ maxWidth: 700 }}
          controls
          src="videos/LauncherInstallation.webm"
        />
      </span>
    ),
  },
  //   {
  //     title: "Create a guardian",
  //     content: (
  //       <p>
  //         The guardian is a safety barrier that warns players if they get too
  //         close to the edges of the playing field to prevent injuries.
  //         <br />
  //         Stand in the middle of your play area and follow the instructions on the
  //         screen. Draw the edges along the sides of the 4.5m x 4.5m area you wish
  //         to designate as the play area. Make sure the edges of the guardian are
  //         far enough from any walls or objects.
  //       </p>
  //     ),
  //   },

  {
    title: "Playing",
    sections: [
      {
        headsetTypes: ["Quest"],
        subtitle: "Launching on the Quest",
        content: (
          <>
            <ol>
              <li>Put on a headset and click on the library icon.</li>
              <li>
                Click on the search box at the top of the app library once.
              </li>
              <li>
                Click on the drop-down that appears next to the search box
              </li>
              <li>Select unknown sources.</li>
              <li>Launch the “WaitingRoom” application.</li>
            </ol>
            <br />

            <video
              style={{ maxWidth: 700 }}
              controls
              src="videos/launchGame.webm"
            />
            <small>
              Note: Make sure your headsets are connected to the same network as
              your server PC.
            </small>
          </>
        ),
      },
      {
        headsetTypes: ["Focus3", "Focus3LBE"],
        subtitle: "Launching on the Focus 3",
        content: (
          <>
            <ol>
              <li>
                Make sure your headsets are connected to the same network as
                your server PC.
              </li>
              <li>Put on a headset and click on the library icon.</li>
              <li>
                Navigate to the “Unknown Sources” tab and launch the
                “WaitingRoom”.
              </li>
            </ol>

            <br />

            <video
              style={{ maxWidth: 700 }}
              controls
              src="videos/LaunchGame_Focus3.webm"
            />
          </>
        ),
      },
      {
        headsetTypes: ["Pico4"],
        subtitle: "Launching on the Pico 4",
        content: (
          <>
            <ol>
              <li>
                Make sure your headsets are connected to the same network as
                your server PC.
              </li>
              <li>Put on a headset and click on the library icon.</li>
              <li>
                Navigate to the “Unknown Sources” tab and launch the
                “WaitingRoom”.
              </li>
            </ol>
            <br />
            <video
              style={{ maxWidth: 700 }}
              controls
              src="videos/LaunchGame_Pico.webm"
            />
          </>
        ),
      },
      {
        subtitle: "The Waiting Room",
        content: (
          <p>
            Players start in the Waiting Room. There they can get used to VR and
            keep occupied while the other players get ready. Players will also
            return to the Waiting Room between games. There they can enter their
            high scores and view previous high scores.
            <br />
            <br />
            <strong>
              The Waiting Room should be open on your PC before you start.
            </strong>
            <br />
            If the Waiting Room isn't running on the server PC you can launch it
            by right-clicking in the launcher and clicking on "open waiting
            room".
            <br />
            <br />
          </p>
        ),
      },

      {
        subtitle: "Starting a Game",
        content: (
          <p>
            <br />
            Click on one of the game thumbnails in the home screen of the
            launcher. Now you can pick a difficulty and you should see a list of
            connected headsets, along with their battery levels. All you need to
            do now is click on "launch game". If there are any problems or you
            want to restart a game you can click on quit to close the game. You
            won't be charged any credits for restarting a game.
          </p>
        ),
      },
    ],
  },
  {
    title: "Calibration",
    headsetTypes: ["Quest", "Focus3", "Pico4"],
    content: (
      <span>
        <ul>
          <li>Put on a headset and open the Waiting room.</li>
          <li>Stand in the center of your play space and face forward.</li>
          <li>
            Hold down the grip buttons and press the thumbsticks down (click the
            thumbsticks inward, do not move the thumbsticks downward) on both
            controllers until you feel a vibration.
          </li>
          <li>
            You need to be in the waiting room or 1 of the games and you need to
            be connected to the server or you won't be able to calibrate.
          </li>
        </ul>

        <ImageWithDescription
          src="/images/manual/calibration.png"
          width={300}
          description="Calibration button combination"
        />
      </span>
    ),
  },

  {
    title: "Connection issues",
    content: (
      <span>
        If the game on the headset is stuck on a loading spinner it means it
        can't connect to the game running on the PC. This can usually be fixed
        by <b>disabling Windows firewall.</b> If that doesn't fix it you can
        take a look at the{" "}
        <a href="/manual#Troubleshooting">troubleshooting section</a> in the
        full manual or contact customer support.
      </span>
    ),
  },

  {
    title: "Multiple Arenas",
    content: (
      <span>
        <p>
          For those who want to run multiple Hero Zone arenas, please refer to
          this page:
        </p>
        <a href="/multiple-arenas">Multiple Arenas</a>{" "}
      </span>
    ),
  },
];
