import React, { Component } from "react";
import { Layout, Menu, Button, Dropdown, BackTop, message } from "antd";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./pages/Login";
import QuickStart from "./pages/manual/QuickStart";
import FullManual from "./pages/manual/FullManual";
import { getToken, setToken, getTokenData, TokenData } from "./utils";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Support from "./pages/Support";
import Api from "./api";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import OculusBusinessSetup from "./pages/OculusBusinessSetup";
import PurchaseCredits from "./pages/credits/PurchaseCredits";
import { CaretDownOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { AffiliateStats } from "./pages/AffiliateStats";
import { RecommendedGear } from "./pages/RecommendedGear";
import { SubscriptionCheckout } from "./pages/subscription/SubscriptionCheckout";
import { Account } from "./pages/account/Account";
import { SubscriptionCheckoutResult } from "./pages/subscription/SubscriptionCheckoutResult";
import { Feedback } from "./pages/Feedback";
import { MultipleInstances } from "./pages/manual/MultipleInstances";
import { Focus3Setup } from "./pages/manual/Focus3Setup";
import { ValidateLoginCode } from "./pages/ValidateLoginCode";
import { DownloadPage } from "./pages/Download";
import { Welcome } from "./pages/Welcome";
import { Marketing } from "./pages/marketing/Marketing";
import { DiagnoseManual } from "./pages/manual/DiagnoseManual";
const { Header, Content } = Layout;
type Props = {};
type State = {
  auth: TokenData | undefined;
  currentMenuKey: string | undefined;
};
class App extends Component<Props, State> {
  state: State = { auth: undefined, currentMenuKey: undefined };
  componentDidMount() {
    this.initAuth();

    const currentMenuKey = window.location.pathname;
    this.setState({ currentMenuKey });
  }

  initAuth = async () => {
    const token = getToken();
    if (!token) return;
    try {
      const auth = getTokenData();
      this.setState({ auth });
      if (!auth || !auth.isValid) {
        message.info("Login expired. Please log in again.");
        setTimeout(() => {
          this.handleLogout();
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
    this.refreshToken();
  };
  refreshToken = async () => {
    try {
      const newToken = await Api.get("/api/refresh_token");
      if (newToken?.token) {
        setToken(newToken.token);
      }
    } catch (error) {
      this.handleLogout();
    }
  };
  handleLogout = () => {
    setToken("");
    // clearCookie("userSession");
    this.setState({ auth: undefined });
    window.location.reload();
  };

  handleMenuClick = (e: any) => {
    this.setState({
      currentMenuKey: e.key,
    });
  };

  render() {
    return (
      <Layout className="layout">
        <Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            color: "#fff",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a href="/">
              <img
                src="./images/logo.png"
                alt="logo"
                style={{ height: 32, margin: 10 }}
              />
            </a>
            <Menu
              disabledOverflow
              onClick={this.handleMenuClick}
              mode="horizontal"
              selectedKeys={[this.state.currentMenuKey || "-1"]}
              selectable
              // defaultSelectedKeys={['1']}
              style={{
                width: "100%",
                lineHeight: "64px",
              }}
            >
              <Menu.Item key="/welcome">
                <a href="/welcome">WELCOME</a>
              </Menu.Item>
              <Menu.Item key="/">
                <a href="/">DASHBOARD</a>
              </Menu.Item>
              <Menu.Item key="/manual">
                <a href="/manual">MANUAL</a>
              </Menu.Item>
              <Menu.Item key="/quick-start">
                <a href="/quick-start">QUICK START</a>
              </Menu.Item>
              <Menu.Item key="/account">
                <a href="/account">ACCOUNT</a>
              </Menu.Item>
              <Menu.Item key={"marketing"}>
                <a
                  href="/marketing"
                  // href="https://drive.google.com/drive/folders/1S2q2Cd_-wMGqYf0Vi_ySBzbcmAj26NRM?usp=sharing"
                  // rel="noopener noreferrer"
                  // target="_blank"
                >
                  MARKETING MATERIALS
                </a>
              </Menu.Item>
              <Menu.Item key={"/feedback"}>
                <a href={`/feedback`}>USER FEEDBACK</a>
              </Menu.Item>
              {/* <Menu.SubMenu style={{ cursor: "auto" }} key="more" title="MORE">
                <Menu.Item key={"/credits"}>
                  <a href={`/credits`}>Buy Credits</a>
                </Menu.Item>
                <Menu.Item key={"marketing"}>
                  <a
                    href="https://drive.google.com/drive/folders/1S2q2Cd_-wMGqYf0Vi_ySBzbcmAj26NRM?usp=sharing"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Get Marketing Materials
                  </a>
                </Menu.Item>
                <Menu.Item key={"/feedback"}>
                  <a href={`/feedback`}>User Feedback</a>
                </Menu.Item>
              </Menu.SubMenu> */}
            </Menu>
          </div>
          <div>
            {this.state.auth ? (
              <div style={{ marginLeft: "auto" }}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <a href="/account">Account</a>
                      </Menu.Item>
                      <Menu.Item onClick={this.handleLogout}>
                        Sign Out
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <div>
                    {this.state.auth?.email}

                    <CaretDownOutlined />
                  </div>
                </Dropdown>
              </div>
            ) : (
              <div style={{ marginLeft: "auto" }}>
                <a href="/login">
                  <Button>LOGIN</Button>
                </a>
              </div>
            )}
          </div>
        </Header>
        <Content
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            padding: "3em",
          }}
        >
          <BackTop />
          <Router>
            <div
              style={{
                maxWidth: "1600px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <PrivateRoute path="/" exact component={Dashboard} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/forgot-password" component={ForgotPassword} />

              {/* <Route path="/signup" component={Signup} /> */}
              <Route path="/login" component={Login} />
              <PrivateRoute path="/credits" component={PurchaseCredits} />
              <PrivateRoute
                path="/purchase-subscription"
                component={SubscriptionCheckout}
              />
              <PrivateRoute
                path="/purchase-subscription-result"
                component={SubscriptionCheckoutResult}
              />
              <PrivateRoute path="/account" component={Account} />

              <PrivateRoute
                path="/oculus-business-setup"
                component={OculusBusinessSetup}
              />

              <Route path="/support" component={Support} />
              <Route path="/quick-start" component={QuickStart} />
              <Route path="/manual" component={FullManual} />
              <Route path="/download" component={DownloadPage} />
              <PrivateRoute path="/welcome" component={Welcome} />
              <Route path="/recommended-gear" component={RecommendedGear} />
              <Route path="/multiple-instances" component={MultipleInstances} />
              <Route path="/multiple-arenas" component={MultipleInstances} />
              <Route path="/diagnostic-tool" component={DiagnoseManual} />
              <PrivateRoute path="/marketing" component={Marketing} />
              <PrivateRoute path="/affiliate" component={AffiliateStats} />
              <PrivateRoute path="/feedback" component={Feedback} />
              <Route path="/focus3-setup" component={Focus3Setup} />
              <Route
                path="/validate_login_code"
                component={ValidateLoginCode}
              />
            </div>
          </Router>
        </Content>
        {/* <Footer style={{ textAlign: "center" }}></Footer> */}
      </Layout>
    );
  }
}

export default App;
